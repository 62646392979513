import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Imgix from '../imgix'
import { IMGIX_CROP_OPTIONS } from '../../constants'

import styles from './hero-image.css'

function HeroImage({
  className,
  image,
  title,
  aspectRatio
}) {
  return (
    <div className={classnames(className, styles.wrapper)}>
      <div className={classnames(className, styles.heroImageWrapper)}>
        <Imgix
          className={className}
          src={image}
          alt={title}
          imgixParams={{ fit: 'crop', crop: IMGIX_CROP_OPTIONS.KEY_ART, ar: aspectRatio }}
          sizes="100vw"
        />
        <div className={styles.gradientBottom} />
        <div className={styles.gradientLeft} />
      </div>
    </div>
  )
}

HeroImage.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  aspectRatio: PropTypes.string
}

HeroImage.defaultProps = {
  title: '',
  className: '',
  aspectRatio: '16:9'
}

export default HeroImage
